import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import Header from './Header'
import Footer from './Footer'
import 'prismjs/themes/prism-tomorrow.css'
import { GlobalStyle } from './Commons'
import { media } from '../tokens'
import { Container, Button, Col, Row } from "react-bootstrap"
import Hero from "./Hero"
import { Link } from "gatsby"
import SEO from '../components/SEO'
import Background from '../components/Background'

const SiteContent = styled.div`
  margin: 0 0;

  @media ${media.minMedium} {
    padding: 80px 0px;
  }
`

class LayoutGeneral extends React.Component {
  render() {
    const { 
      children,
      location,
      heroImg,
      title,
      description, 
    } = this.props

    return (
      <>
        {/*<Helmet>
          <link
            href="https://fonts.googleapis.com/css?family=Lato:400,700&display=swap"
            rel="stylesheet"
          />
        </Helmet>*/}
        <SEO
          title={title}
          description={description}
          cover={heroImg}
          path={location}
        />
        <GlobalStyle />
        <Header location={location}/>
        <Background className="test">
          <SiteContent>{children}</SiteContent>
        </Background>
        <Footer />
      </>
    )
  }
}

class LayoutPage extends React.Component {
  render() {
    const { 
      children,
      location,
      heroImg,
      title,
      description,
    } = this.props

    return (
      <LayoutGeneral location={location} {...this.props}>
        <Hero
          heroImg={heroImg}
          title={title}
        />

        <Container>{children}</Container>
        
      </LayoutGeneral>
    )
  }
}

export default LayoutGeneral
export {
  LayoutPage
}