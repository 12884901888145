import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

const Background = ({ className, children }) => {
  
  return (

  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "Backgrounds/dypsloomWEBbackground-cloudsMonochrome.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          //Tag="body"
          className={className}
          fluid={imageData}
          //backgroundColor={`#040e18`}
          style={{
            // Defaults are overwrite-able by setting one or each of the following:
            backgroundSize: '',
            backgroundPosition: '',
            backgroundRepeat: '',
          }}
        >
          {children}
        </BackgroundImage>
      )
    }}
  />
)
  }

const StyledBackground = styled(Background)`
  width: 100%;
  height: 100%;
  background-position: bottom center;
  background-repeat: repeat;
  background-size: cover;
`

export default StyledBackground