/* eslint-disable react-hooks/rules-of-hooks */

import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import useSiteMetadata from '../hooks/use-site-config'
import { colors, media } from '../tokens'
import useSiteImages from '../hooks/use-site-images'
import { Navbar, Nav, NavDropdown, Container, Row, Col, Form, FormControl, Button } from 'react-bootstrap'
import Img from "gatsby-image"



const HeaderWrapper = styled.header`
  height: 80px;
  top: 0;
  left: 0;
  margin: 0 auto;
  display: flex;
  width: 100%;
  z-index: 1000;
  background-color: ${colors.primary};
  color: ${colors.textLightest};
  font-weight: 700;

  @media ${media.minMedium} {
    position: fixed;
  }

  @media ${media.maxMedium} {
    .navbar-dark {
      z-index: 2000;
      background-color: ${colors.primary};
      color: ${colors.textLightest};
      display: inline-block;
      width: 100%;
    }
  
    .navbar-collapse{
      background-color: ${colors.primary};
      position: absolute;
      border-bottom-right-radius: 30px;
      border-bottom-left-radius: 30px;
      width: 100%;
      right: 0;
    }
  }

 

  .navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 1);
  }

  .navbar-toggler{
    right: 0;
    position: absolute;
  }

  .dropdown-menu {
    
    left: 0;
    float: left;
    min-width: 12rem;
    margin-top: 0;
    padding: 0.5rem 0;
    font-size: 1rem;
    color: white;
    text-align: left;
    list-style: none;
    background-color: ${colors.primary};
    background-clip: padding-box;
    border: 0;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
  }

  .dropdown-item a{
    color: white;
    width: 100%;
    height: 100%;
    display: block;
  }
  .dropdown-item a:hover{
    color: ${colors.dypPurpleLight}
  }

  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: transparent;
}

  .dropdown-toggle{
    margin-bottom: 2px;
  }
  .navbar .dropdown-toggle, .nav-tabs .dropdown-toggle{
    margin-bottom: 0;
  }

  /* Before Burger */
  @media screen and ${media.minMedium} {
    .dropdown:hover .dropdown-menu, .btn-group:hover .dropdown-menu{
      display: block;
    }
    
  }

  /* Inside burger */
  @media ${media.maxMedium} {
    .btn-group{
      flex-direction: column;
    }

    .dropdown .dropdown-menu, .btn-group .dropdown-menu{
      display: block;
      margin-left: 50px; 
    }
  }
`

const HeaderLink = styled(Link)`
  font-size: 20px;
  position: relative;
  display: flex;
  align-items: center;
  color: ${colors.textLightest};
  border: 0;
  margin: 0 30px 0 30px;
  padding: 10px 1px 10px 10px;

  min-width: 42px;
  z-index: 10;
  & + & {
    margin-left: 0.7rem;
  }

  ::after{
    transition: all ease 0.8s;
    Content: "";
    position: absolute;
    display: block;
    z-index: -1;
    margin: 14px 0px 0px 0px;
    height: 4px;
    background-color: ${colors.dypBlue};
  }

  &.header-link-selected::after , :hover::after {
    width: 70px;
  }
  :hover{
    color: rgba(255, 255, 255, 1);
    text-decoration:none;
  }
  :hover::after {
    box-shadow: inset 70px 0 0 0 ${colors.dypPurpleLight};
  }
`

const HeaderDropDowns = ({ headerLinks, location }) => {
  const currentSlug = location.pathname != null ? location.pathname : location 
  const currentSection = currentSlug.split('/')[1]
  return headerLinks.map((headerLink, i) => (
    <Nav.Item as="li" className="btn-group">
        <HeaderLink to={headerLink.url} className={headerLink.label == currentSection ? "header-link-selected" : ""}>
          {headerLink.label}
        </HeaderLink>
        <ul className="dropdown-menu">
          <HeaderDropDownItems links={headerLink.links}></HeaderDropDownItems>
        </ul>
    </Nav.Item>
  ))
}

const HeaderDropDownItems = ({ links }) => {
  return links.map((link, i) => {
    if(link.url.startsWith("http")){
      return (    
        <NavDropdown.Item as='li'><a href={link.url}>{link.label}</a></NavDropdown.Item>
      )
    }else{
      return (    
        <NavDropdown.Item as='li'><Link to={link.url}>{link.label}</Link></NavDropdown.Item>
      )
    }
    
  })
}

const HeaderContainer = styled(Container)`
  align-self: center;
`

const Header = props => {
  const { location } = props
  const {
    siteTitle,
    headerLinksIcon,
    headerLinks
  } = useSiteMetadata()
  const iconSrc = headerLinksIcon
    ? useSiteImages(headerLinksIcon).fixed
    : null

  return (
    <HeaderWrapper>
      <HeaderContainer>
        <Navbar expand="md" variant='dark'>
          
          <Navbar.Brand href="/">
            <img src={iconSrc.src} width="250" alt="dypsloom-logo-text"></img>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <HeaderDropDowns headerLinks={headerLinks} location={location}></HeaderDropDowns>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </HeaderContainer>
    </HeaderWrapper>
  )
}

export default Header